<template>
  <vs-card>
    <doctor-edit
      fetchClinicsBy="admin"
      :canDelete="true"
      :adminApprovalVar="true"/>
  </vs-card>
</template>
<script>

  import doctorEdit from '../../components/doctors/editDoctor.vue'

  export default {
    components: {
      doctorEdit
    },
    data:()=>({
    })
  }
</script>
